import React, { ChangeEvent, useCallback } from "react";

import "./SideRail.css";
import { Person } from "../types";

interface Props {
  person: Person;
  onPersonChange: (key: keyof Person, value: string) => void;
  signatureHTML: string;
  signatureDivID?: string;
}

const SideRail: React.FC<Props> = ({
  person,
  onPersonChange,
  signatureHTML,
  signatureDivID = "signature",
}) => {
  const handleCopySignature = useCallback(() => {
    const selection = document.getSelection();
    if (!selection) {
      alert("Your browser is not supported");
      return;
    }

    selection.removeAllRanges();
    const range = document.createRange();
    const sigElement = document.getElementById(signatureDivID);
    if (!sigElement) {
      console.error("No signature element found?");
      return;
    }
    range.selectNode(sigElement);
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
  }, [signatureDivID]);
  const handleCopySignatureHTML = useCallback(() => {
    const selection = document.getSelection();
    if (!selection) {
      alert("Your browser is not supported");
      return;
    }

    selection.removeAllRanges();
    const textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.top = "-9999px";
    textarea.value = document.getElementById(signatureDivID)?.innerHTML ?? "";
    console.info(textarea.value);
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }, [signatureDivID]);
  const handleFieldChange = (key: keyof Person) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    onPersonChange(key, e.currentTarget.value);
  };
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  return (
    <div className="siderail">
      {isChrome && (
        <div className="warning" role="alert">
          <p>
            <strong>Warning</strong>
          </p>
          <p>
            Google Chrome adds an underline to links in a signature. Use another
            browser like Firefox, Safari or Microsoft Edge to copy the signature
            and paste it into GMail.
          </p>
        </div>
      )}
      <h1>Enter Your Details</h1>

      <div className="field">
        <label htmlFor="input_first_name">First Name</label>
        <input
          id="input_first_name"
          type="text"
          value={person.firstName}
          onChange={handleFieldChange("firstName")}
          placeholder="Jane"
        />
      </div>

      <div className="field">
        <label htmlFor="input_last_name">Last Name</label>
        <input
          id="input_last_name"
          type="text"
          value={person.lastName}
          onChange={handleFieldChange("lastName")}
          placeholder="Doe"
        />
      </div>

      <div className="field">
        <label htmlFor="input_title">Title</label>
        <input
          id="input_title"
          type="text"
          value={person.title}
          onChange={handleFieldChange("title")}
          placeholder="Venture CTO"
        />
      </div>

      <div className="field">
        <label htmlFor="input_phone">Phone</label>
        <input
          id="input_phone"
          type="text"
          value={person.phone}
          onChange={handleFieldChange("phone")}
          placeholder="+1 555-555-5555"
        />
      </div>

      <div>
        <button
          type="button"
          onClick={handleCopySignature}
          style={{ marginRight: ".5rem" }}
        >
          Copy Signature
        </button>
        <button type="button" onClick={handleCopySignatureHTML}>
          Copy as HTML
        </button>
      </div>
      <p className="instructions">
        For GMail, click{" "}
        <button onClick={handleCopySignature}>Copy Signature</button> and follow{" "}
        <a
          href="https://support.google.com/mail/answer/8395?co=GENIE.Platform%3DDesktop&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          these instructions
        </a>
        .
      </p>
    </div>
  );
};

export default SideRail;
