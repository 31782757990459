import React from "react";
// Fine to use local stuff for testing, but we cannot use these in prod as
// Netlify nukes all static assets inbetween deploys so we use Google Cloud
// Storage instead
// import KoruLogoSmall from "./koru-logo-small-sig3.png";
// import HorizontalDivider from "./horizontal-divider.png";
// import IconLink from "./icon-link-red.png";
// import IconPhone from "./icon-phone-red.png";
import { Person } from "../types";

const KORU_LOGO_HEIGHT = 40;
const ICON_SIZE = 10;
const HorizontalDivider =
  "https://storage.googleapis.com/signature-hellokoru-com/media/horizontal-divider.93966c8c.png";
const KoruLogoSmall =
  "https://storage.googleapis.com/signature-hellokoru-com/media/koru-logo-small-sig3.da71f935.png";
const IconPhone =
  "https://storage.googleapis.com/signature-hellokoru-com/media/icon-phone-red.2eb36907.png";
const IconLink =
  "https://storage.googleapis.com/signature-hellokoru-com/media/icon-link-red.f013f276.png";

const Signature: React.FC<Person> = (props) => {
  return (
    <div id="signature">
      <table
        cellPadding={0}
        cellSpacing={0}
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          verticalAlign: "-webkit-baseline-middle",
        }}
      >
        <tbody>
          <tr style={{ height: 30 }}></tr>
          <tr>
            <td>
              <img
                style={{
                  display: "block",
                }}
                src={HorizontalDivider}
                alt="Horizontal divider"
              />
            </td>
          </tr>
          <tr style={{ height: 30 }}></tr>
          <tr>
            <td style={{ fontSize: 14 }}>
              <span style={{ fontWeight: "bold", color: "#3B3A55" }}>
                {props.firstName} {props.lastName}
              </span>
              <span style={{ color: "#666666" }}> | {props.title}</span>
            </td>
          </tr>
          <tr style={{ height: 20 }}></tr>
          <tr>
            <td>
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={KoruLogoSmall}
                        alt="Koru Logo"
                        height={KORU_LOGO_HEIGHT}
                        style={{
                          maxHeight: KORU_LOGO_HEIGHT,
                          display: "block",
                        }}
                      />
                    </td>
                    {props.phone && (
                      <>
                        <td style={{ width: 15 }}></td>
                        <td valign="middle">
                          <table cellSpacing={0} cellPadding={0}>
                            <tbody>
                              <tr>
                                <td style={{ padding: 0 }}>
                                  <img
                                    src={IconPhone}
                                    alt="Phone Icon"
                                    width={ICON_SIZE}
                                    style={{
                                      display: "block",
                                      maxWidth: ICON_SIZE,
                                    }}
                                  />
                                </td>
                                <td style={{ width: 10 }}></td>
                                <td style={{ padding: 0 }}>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "#666666",
                                    }}
                                  >
                                    {props.phone}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 0 }}>
                                  <img
                                    src={IconLink}
                                    alt="Link Icon"
                                    width={ICON_SIZE}
                                    style={{
                                      display: "block",
                                      maxWidth: ICON_SIZE,
                                    }}
                                  />
                                </td>
                                <td style={{ width: 10 }}></td>
                                <td>
                                  <span
                                    style={{
                                      color: "#666666",
                                      fontSize: 12,
                                    }}
                                  >
                                    www.hellokoru.com
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Signature;
