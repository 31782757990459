import React, { useState } from "react";
import "./App.css";
import SideRail from "./components/SideRail";
import Signature from "./components/Signature";
import { Person } from "./types";
import { useTitle } from "./utils";

function App() {
  useTitle("Koru - Signature Maker");
  const [person, setPerson] = useState<Person>({
    firstName: "Jane",
    lastName: "Doe",
    title: "Venture CTO",
    phone: "+1 555-555-5555",
  });
  const handlePersonChange = (key: keyof Person, value: string) => {
    setPerson((person) => ({
      ...person,
      [key]: value,
    }));
  };

  return (
    <div className="app">
      <SideRail
        person={person}
        onPersonChange={handlePersonChange}
        signatureHTML="todo"
      />
      <div className="preview">
        <div className="chrome">
          <div className="chrome-header">
            <div>To: someone@example.com</div>
            <div>Subject: New signature!</div>
          </div>
          <div className="chrome-preview">
            <p>Hi there,</p>
            <p>
              This is just to give you an idea of what an email looks like with
              your new signature!
            </p>
            <p>
              Cheers,
              <br />
              {person.firstName}
            </p>
            <Signature {...person} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
